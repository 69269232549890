 // todo: do not include it in the js bundle - load on demand (already done for pdf media viewer but disabled for now)
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
// import {AppModule} from "./app/app.module";
// import {BootstrapModule} from "bootstrap"; // direct usage causes compile-time errors
import {AppBootstrapModule} from "./app/app-bootstrap.module";

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.debug = function(){};
  }
}

platformBrowserDynamic()
  // .bootstrapModule(AppModule)
  .bootstrapModule(AppBootstrapModule)
  .catch(err => console.error(err));
