import {NgModule} from '@angular/core';
import {BootstrapComponent, BootstrapModule} from "launcher";
import {environment} from "../environments/environment";
import {ServiceWorkerModule} from "@angular/service-worker";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";

@NgModule({
  imports: [

    /*
     * OneSignalSDKWorker.js is modified to include angular's ngsw-worker.js as per OneSignal service worker customizations guide:
     *
     * "OneSignalSDKWorker.js and OneSignalSDKUpdaterWorker.js overwrite other service workers that are registered with
     * the topmost (site root) service worker scope. The solution is to merge all other service worker scripts into
     * our service worker scripts using importScripts() and to register the merged service worker instead of the original worker."
     *
     * https://documentation.onesignal.com/docs/onesignal-service-worker-faq#custom-code-setup---service-worker-customizations
     */
    // ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('/OneSignalSDKWorker.js', { enabled: environment.production }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    BootstrapModule.create(
      { environment: environment },
      'projects/apps/lifechanger/src/app/app.module#AppModule',
      () => import('projects/apps/lifechanger/src/app/app.module').then(m => m.AppModule),
      // (key: string) =>
      //   key.startsWith('interests.help.') ||
      //   key.startsWith('purchase.stripeError.') ? '' : key
    )
  ],
  bootstrap: [ BootstrapComponent ]
})
export class AppBootstrapModule {
}
