// `.env.ts` is generated by the `npm run env` command
import { env } from './.env';

export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  defaultLanguage: 'en',
  supportedLanguages: [
    'en'
  ],
  // serverUrl: 'https://mb.lifechanger.pro',
  // serverUrl: 'https://mb.lifechanger.pro:8443', //use when dns lookup is provided by hosts file (no router port forwarding)
  serverUrl: 'https://mb.lifechanger.pro',
  cdnUrl: 'https://1509039800.rsc.cdn77.org',
  app: 'lifechanger'
};

